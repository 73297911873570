<template>
  <div class="section">
    <div class="container">
      <div class="row row-split-content">
        <div class="content-width-small" style="text-align: center;">
        
          <h6 class="subheading text-primary-1 today">Today is {{ today }}!</h6>
          <h3 class="h1" style="font-size: 27px; text-align: left; font-style: italic; margin-bottom: 19px">{{post.post_title}}</h3>
          <div style="text-align: justify;">{{this.descriptionFilterBranding}}</div>
          <a href="#" class="button w-inline-block today-download">
            <div>
              <a
                v-on:click="track('download')"
                v-bind:href="download"
                class="button w-inline-block post-download"
                download
              >
                <div>
                  Download
                  <i class="fas fa-download"></i>
                </div>
              </a>
            </div>
          </a>
          <a
            v-bind:href="post.editable_link"
            class="button w-inline-block post-edit-featured"
            download
            v-on:click="track('edit')"
            target="_blank"
            v-if="post.editable_link !== null && post.editable_link!==''"
          >
            <div>
              Edit
              <i class="fas fa-edit"></i>
            </div>
          </a>
          <ShareButton :post=post />
        </div>
        <div class="content-width-large">
          <div data-animation="slide" data-duration="500" data-infinite="1" class="slider w-slider">
            <div class="w-slider-mask">
              <div class="w-slide">
            <img v-if="!this.post.animated" v-bind:src="post.media" width="512.5" />
            <video v-else v-bind:src="post.media" alt class="card-video-head" autoplay muted loop playsinline width="512.5"></video>
              </div>
              <!-- <div class="w-slide">
                <img
                  src="assets/square-5.jpg"
                  width="612.5"
                  srcset="images/square-5-p-500.jpeg 500w, images/square-5-p-800.jpeg 800w, images/square-5-p-1080.jpeg 1080w, images/square-5.jpg 1224w"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 360px, (max-width: 991px) 612px, 47vw"
                  alt
                />
              </div>
              <div class="w-slide">
                <img
                  src="assets/square-8.jpg"
                  width="612.5"
                  srcset="images/square-8-p-500.jpeg 500w, images/square-8-p-800.jpeg 800w, images/square-8-p-1080.jpeg 1080w, images/square-8.jpg 1224w"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 360px, (max-width: 991px) 612px, 47vw"
                  alt
                />
              </div>-->
            </div>
            <!-- <div class="slider-arrow w-slider-arrow-left">
              <div class="button-circle slider-arrow-button bg-gray-4">
                <img src="images/icon-arrow-left.svg" alt />
              </div>
            </div>
            <div class="slider-arrow slider-arrow-right w-slider-arrow-right">
              <div class="button-circle slider-arrow-button bg-gray-4">
                <img src="images/icon-arrow-right.svg" alt />
              </div>
            </div>-->
            <div class="display-none w-slider-nav w-round"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ShareButton from "@/components/ScheduleButton.vue";
export default {
  data() {
    return {
      today: moment(Date.now()).format("MMMM Do"),
      butterDate: Date.now(),
      post: {}
    };
  },
    components:
  {
    ShareButton
  },
  computed: {
    currentDate: function() {
      var nowWithTime = Date.now();
      var noTime = moment(nowWithTime).startOf("day");
      return noTime;
    },
    download: function() {
      var media = this.post.media;
      if (media) {
        media = media.substring(26);
      var extension = ".jpg";
      if(this.post.animated)
      {
        extension = ".mp4";
      }
      var safeTitle = encodeURI(this.post.post_title + extension);

        var url =
          "https://fs.buttercms.com/content=t:attachment,f:%22" +
          safeTitle +
          "%22/" +
          media;
        return url;
      }
      return "";
    },
    descriptionFilterBranding()
    {
      //filters out agent nest branding for white label
      if(this.post.description!=null && process.env.VUE_APP_WHITELABEL=='true')
      {
         var post = this.post.description.replaceAll("#theagentnest","")
        return (post.replaceAll("#agentnest",""));
      }
      return this.post.description;
    }
  },
  methods: {
    track(edit) {
      var title = "Downloaded Featured Post";
      if (edit == "edit") {
        title = "Edited Featured Post";
      }
      // eslint-disable-next-line no-undef
      mixpanel.track(title, {
        Title: this.post_title,
        Calendar: true,
        Category: this.post.type
      });
    }
  },
  mounted() {
    this.$root.$on("eventing", data => {
      //The featured post of the day comes from the calendar. It emits the post here so we don't have to call api twice
      //only supports one post for now
      this.post = data[0];
    });
  }
};
</script>

<style scoped>
.post-edit-featured {
  margin-left: 15px;
  padding: 5px 10px;
  cursor: pointer;
}
.today
{
  font-size: 24px;
}
</style>